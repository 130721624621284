import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import * as FTIcon from 'react-feather';
import { Button,Icon } from '@blueprintjs/core';
import { toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class DictItem extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    async removeDict( dict )
    {
        if( !window.confirm("确定要删除词库"+dict.name+"吗？背词记录也将一起被删除") ) return false;

        // if( await this.props.store.dict_remove( dict.id ) )
        // {
            await this.props.store.dict_remove( dict.id )
            // 删除本地词库
            this.props.store.setSettings( "local_dicts", this.props.store.local_dicts.filter( item => item != dict.id ));

            if( this.props.store.dict == dict.id ) this.props.store.setSettings( 'dict','cet4-cute');

            if( this.props.onUpdated ) this.props.onUpdated();
        //}
    }

    async setDict( dict )
    {
        if( this.props.store.level < 2 )
        {
            this.props.store.mlog( 'dict' , 'memberonly' );
            toast("该词库只对Fox会员开放");
            return false;
        }

        if( dict.r15 && dict.r15 > 0 )
        {
            if( !window.confirm("该词库不适合在办公环境使用且仅对15岁以上用户开放，是否切换？") ) return false;

            this.props.store.mlog( 'dict' , 'r15|'+dict.id );
        }
        else
        {
            this.props.store.mlog( 'dict' , dict.id );
        }
        
        
        await this.props.store.set_dict( dict.id );
    }
    
    render()
    {
        const member_only = this.props.data.r15 && this.props.data.r15 > 0;
        const member = member_only ? ' member ' : '';
        
        const active = this.props.data.id === this.props.store.dict ? ' active ' : '';
        
        return this.props.data ? <li className={active+member}>
            <div className="left vcenter" onClick={()=>this.setDict(this.props.data)}  >
    { member_only ? <Icon icon="crown" iconSize={20} className="right5" /> : '' }{this.props.data.name}
            </div>
            <div className="right xcenter">
            { this.props.data.local && this.props.data.local == 1 ?<Button minimal={true} icon={<FTIcon.Trash />} onClick={()=>this.removeDict(this.props.data)}/>: <Button minimal={true} icon={<FTIcon.BookOpen />} onClick={()=>this.setDict(this.props.data)}/>}
            
            </div>
        </li> : null; 
    }
}