import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import * as FTIcon from 'react-feather';
import { Button, Switch, Overlay, Icon } from '@blueprintjs/core';
import { toast , isApiOk , showApiError, isWechat } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class SaleItem extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    async buy( id )
    {
        await this.props.store.mlog( 'buy_button_click' , id );
        // 判断是否在微信中
        if( !isWechat() )
        {
            toast("🦊 请在微信中打开 fox.ftqq.com 来支付");
            return false;
        }
        
        const { data } = await this.props.store.get_pay_info( id );
        if( isApiOk( data ) )
        {
            if( data.data )
            {
                const jsinfo = JSON.parse( data.data );
                if( jsinfo && jsinfo.appId )
                {
                    // 通过 js bridge 唤起微信支付
                    // 定义回调函数
                    let that = this;

                    // 定义回调函数
                    function onBridgeReady() 
                    {
                        /*global WeixinJSBridge:true*/
                        /*eslint no-undef: "error"*/
                        WeixinJSBridge.invoke(
                           'getBrandWCPayRequest', {
                              "appId":jsinfo.appId,     //公众号名称，由商户传入     
                              "timeStamp":jsinfo.timeStamp,         //时间戳，自1970年以来的秒数     
                              "nonceStr":jsinfo.nonceStr, //随机串     
                              "package":jsinfo.package,     
                              "signType":"MD5",         //微信签名方式：     
                              "paySign":jsinfo.paySign //微信签名 
                           },
                           async (res)=>{
                           if(res.err_msg == "get_brand_wcpay_request:ok" )
                           {
                                await that.props.store.update_user();
                                await this.props.store.mlog( 'buy_client_finished' , id );
                                that.props.history.push("/settings");
                                toast("会员有效期已经增加");
                           }else
                           {
                               // alert( JSON.stringify(res) );
                           } 
                        }); 
                    }

                    if (typeof WeixinJSBridge == "undefined")
                    {
                        if( document.addEventListener ){
                            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                        }else if (document.attachEvent){
                            document.attachEvent('WeixinJSBridgeReady', onBridgeReady); 
                            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                        }
                     }
                     else
                     {
                        onBridgeReady();
                     }


                    
                    // console.log( jsinfo );
                }
                

            }
           
        }
        else showApiError( data );

    }
    
    render()
    {
        return this.props.data && <li>
            <div className="left">
                <div className="price">
                    {(this.props.data.price/100).toFixed(2)}

                    <span className="full">
                    {(this.props.data.full_price/100).toFixed(2)}
                    </span>
                </div>
                { this.props.data.title }
            </div>
            <div className="right">
            <Button onClick={()=>this.buy(this.props.data.id)} text="特惠购买" />
            </div>
        </li>;
    }
}