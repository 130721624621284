import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import {Howl, Howler} from 'howler';
import Logo from '../component/Logo'; 
import equal from 'fast-deep-equal';
import { toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class TypeBox extends Component
{
    constructor(props) 
    {
        super(props);
        this.state = {"typed":"","errored":false};
        this.onKeydown = this.onKeydown.bind(this);
    }
    

    componentDidUpdate(prevProps)
    {
        if(!equal(this.props.answer, prevProps.answer))
        {
            console.log( "updated..." );
            this.setState({ "typed":"","errored":false});
        }
       
    }
    
    componentDidMount()
    {
       window.addEventListener( "keydown" , this.onKeydown , false ); 

       ['right','wrong','type','success','back'].forEach( sound =>
        {
            this[sound] = new Howl({src: [sound+'.mp3']});
        } );

        this.props.store.event.on( 'float-keydown' , ( e )=> {
            if( e.key ) this.keydown(e);
        } );
       
    }

    play( name )
    {
        if( this.props.store.sound === 'on' && this[name] ) this[name].play();
    }

    componentWillUnmount()
    {
        this.props.store.event.removeListener( 'float-keydown' );
        window.removeEventListener( "keydown", this.onKeydown , false );
    }

    onKeydown( e )
    {
        return this.keydown( e );
    }

    keydown( e )
    {
        // console.log(e);
        let new_typed = this.state.typed;
        
        if( this.state.typed == this.props.answer )
        {
            if( e.code === 'Space' )
            {
                if( this.props.onGoNext ) this.props.onGoNext();
            }

            if( e.key === '1' )
            {
                if( this.props.onFavorite ) this.props.onFavorite( 1 );
            }

            if( e.key === '0' )
            {
                if( this.props.onFavorite ) this.props.onFavorite( 0 );
            }

            return false;
        }
        
        

        
        
        
        if( e.key === 'ArrowRight' )
        {
            this.setState({"typed":this.props.answer});

            this.play('success');
            if( this.props.onSuccess ) this.props.onSuccess();

            if( e.stopPropagation ) e.stopPropagation();
            if( e.preventDefault ) e.preventDefault();

            return false;
        }

        if( e.key === 'Tab' )
        {
            if( this.props.onToggleAnswer ) this.props.onToggleAnswer();
            
            if( e.stopPropagation ) e.stopPropagation();
            if( e.preventDefault ) e.preventDefault();

            return false;
        }


        
        // 删除键
        if( e.keyCode === 8 )
        {
            new_typed = new_typed.substring(0,new_typed.length-1);

            // 先要取出最短相同长度，然后在set透明度
            let same_max = 0;
            for( let j=1; j <= new_typed.length; j++  )
            {
                if( new_typed.substring(0,j) === this.props.answer.substring(0,j) ) same_max = j;
            }

            if( same_max > 0 )
            {
                this.setOpacity( new_typed.substring(0,same_max) );
            }
            else
            {
                this.setOpacity('');
            }

            // 禁止事件行为和冒泡
            if( e.stopPropagation ) e.stopPropagation();
            if( e.preventDefault ) e.preventDefault();
                

            this.play('back');
        }
        else
        {
            // console.log( e.keyCode );
            if( 'qwertyuiopasdfghjklzxcvbnm-'.split("").includes( e.key ) && !e.altKey && !e.metaKey && !e.ctrlKey )
            {
                // 禁止事件行为和冒泡
                if( e.stopPropagation ) e.stopPropagation();
                if( e.preventDefault ) e.preventDefault();
                
                if( new_typed.length > this.props.answer.length-1 )
                {
                    this.play('wrong');
                    return false;
                } 
            
                new_typed = new_typed + e.key;

                if( this.props.answer.substring( 0 , new_typed.length ) === new_typed )
                {
                    if( this.props.answer.length === new_typed.length )
                    {
                        if( this.props.sound && this.props.store.word_sound === 'on' )
                        {
                            console.log( this.props.sound );
                            new Howl({src: [this.props.sound],html5:true}).play();
                        }
                        else
                        {
                            if( this.props.store.sound === 'on' )
                            this.play('success');
                        }
                            
                        // console.log( "typebox errored",this.state.errored )
                        this.setOpacity( this.props.answer);
                        if( this.props.onSuccess ) this.props.onSuccess( this.state.errored );
                    }
                    else
                    {
                        this.play('right');

                        // 修改透明度
                        // 透明度设置为 40~90之间
                        // 差值为50
                        this.setOpacity( new_typed );
                        

                    }
                }
                else
                {
                    // 输入错误
                    // if( this.props.store.first_error !== 'off' )
                    // {
                    //     toast('🦊 点击键盘最下方的辅助按钮，可以显示释义、跳过和删除字符');
                    //     this.props.store.setSettings( 'first_error' , 'off' );
                    // }
                    
                    this.play('wrong');
                    // error 不再实时上报，而是和成功结果一起推送上去
                    this.setState({"errored":true}); 
                }
            }
            
            

        }

        this.setState( {"typed":new_typed} );

        
    }



    

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }

    setOpacity( new_typed )
    {
        const sub = parseInt(new_typed.length/this.props.answer.length * 50);

        if( this.props.onSetPiano ) this.props.onSetPiano( new_typed.length , this.props.answer.length );

        if( this.props.onSetOpacity ) this.props.onSetOpacity( ((90-sub)/100) );
    }

    
    
    render()
    {
        let character_size = parseInt(100/this.props.answer.length);
        if( character_size > 30 ) character_size -= 10;
        let character_style = {"fontSize":character_size+'vw'};
        
        // 如果屏宽大于600，使用px
        if( window.innerWidth > 600 )
        {
            character_style= {"fontSize":character_size/100*600+'px'};
        }
        
        
        
        let typed_count = 1;
        let show_array =this.state.typed.split("");
        if( this.props.answer && this.props.answer.length > this.state.typed.length )
        {
            for( let ii = 0 ; ii < this.props.answer.length ; ii++ ){
                if( !show_array[ii] || show_array[ii].length < 1 ) show_array[ii] = " ";
            }
        }
        
        return this.props.answer && this.props.answer.length > 1 ? 
        <div>
            {this.state.typed.length > 0  ? show_array.map( item =>
            {
                return <span style={character_style} className="typebox" key={typed_count++}>{item}</span>
            } )  : <Logo /> }
        </div> : null;
    }
}