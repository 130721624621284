import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import * as FTIcon from 'react-feather';
import { Button, Switch, Overlay, Icon } from '@blueprintjs/core';
import { toast,isWechat } from '../util/Function';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode.react';


@withRouter
@inject("store")
@observer
export default class Share extends Component
{
    state = {"image":false,"genereating":false,"share_image":false};
    
    componentDidMount()
    {
        const image_id = this.props.match.params.id;
        this.load_image( image_id );
         
    }

    componentDidUpdate()
    {
        if( !this.state.image ) return false;
        window.setTimeout( ()=>
        {
            const cover = document.querySelector("img.cover");
            const nh = window.innerHeight*0.8;

            if( cover.height < nh )
            {
                
                
                const newwidth = cover.width * (nh / cover.height)
                
                console.log( "too low" , cover.height ,nh , "newwidth" , newwidth);

                document.querySelector(".preview").style="min-height: "+nh+"px;overflow-x:hidden;";
                document.querySelector(".titlebox").style="z-index:19";

                const left_fix = ( window.innerWidth - newwidth )/2  + "px" ;
                

                cover.style = "max-width:"+newwidth+"px;width:"+newwidth+"px;height:"+nh+"px;left:"+ left_fix+";position: absolute;z-index:0";
            }
        }, 100 );
        
    }

    async load_image( id )
    {
        const image = await this.props.store.get_image_by_id( id );
        if( image ) this.setState({"image":image});
        console.log( image );
    }

    async share_image()
    {
        const canvas = await html2canvas( document.querySelector(".preview") );
        if( canvas )
        {
            if( !isWechat() )
            {
                toast("🦊 海报已生成，可分享到群组、朋友圈和微博");
                saveAs( canvas.toDataURL() , "foxdict.png" );
            }
            else
            {
                this.setState({"share_image":canvas.toDataURL()});
                toast("🦊 微信中请长按下图存到相册，即可分享到群组、朋友圈和微博");
                
                //document.querySelector(".preview").innerHTML="<img scr='" + canvas.toDataURL() + "' class='overlay' />"
            }

            this.props.store.mlog('share_image_built');
        } 


    }

    render()
    {
        console.log( "uid" , this.props.store.uid );
        
        if( !this.state.image ) return null;
        this.props.store.mlog('share_page');
        const left = <Button minimal={true} icon={<FTIcon.ArrowLeft />} onClick={()=>this.props.history.goBack(1)} />;

        const image_url = this.state.image.image;

        const site_url = this.props.store.uid > 0  ? window.origin + '#/ref/'+this.props.store.uid : window.origin ;

        let typed_count = 1;
        let character_size = parseInt(100/this.state.image.word.length);
        if( character_size > 30 ) character_size -= 10;
        let character_style = {"fontSize":character_size+'vw'};
        
        // 如果屏宽大于600，使用px
        if( window.innerWidth > 600 )
        {
            character_style= {"fontSize":character_size/100*600+'px'};
        }

        return <div className="fox-std-page fox-share-page">
            <div className="header underline">
                <div className="left">
                {left}
                </div>
                <div className="center">
                分享
                </div>
                <div className="right">
                </div>
            </div>
            
            { this.state.share_image ? <img className="share" src={this.state.share_image} /> : 
            <div className="share-box">
            <div className="actionbar">
                <Button text="点我生成分享海报" minimal={true} className="outline white" onClick={()=>this.share_image()} large={true} icon={<span className="bp3-icon"><FTIcon.Share2 size="20" /></span>}/>
            </div>
            <div className="preview">
                <div className="titlebox">
                    <div className="title">
                        {this.state.image.word.length > 0  && this.state.image.word.split("").map( item =>
                        {
                            return <span style={character_style} className="typebox" key={typed_count++}>{item}</span>
                        } ) }
                    </div>
                    <div className="desp">
                        <div>看福利 • 背单词 • 超好玩</div>
                        <div>🦊 fox.ftqq.com </div>
                        <div className="qrcode">
                            <QRCode value={site_url} renderAs="svg" size={60} includeMargin={true} />    
                        </div>
                    </div>
                </div>
                
                <img className="cover" src={image_url} alt={this.state.image.word}/>
            </div>
            
            

        </div>
            }
            
        </div>
    }
}