import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Button, Switch, Overlay } from '@blueprintjs/core';
import { toast , isApiOk , showApiError } from '../util/Function';


@withRouter
@inject("store")
@observer
export default class Login extends Component
{
    state = {"qr_url":false,"token":false}
    
    componentDidMount()
    {
        this.load_qrcode();
    }

    // 检查服务器端登入情况
    async check_qrcode()
    {
        const { data } = await this.props.store.check_qrcode();
        if( isApiOk( data ) )
        {
            if( data.data )
            {
                const { result } = data.data;
                result.token = this.props.store.token;
                this.props.store.set_user( result );

                this.props.store.mlog( 'logined' );

                toast("欢迎回来，"+result.nickname);
                this.props.history.replace("/");
            }
            else
            {
                toast("没有检测到登入操作，请确认微信已关注并收到登入提示");
            }
        } else showApiError( data );
    }

    async load_qrcode()
    {
        
        const { data } = await this.props.store.load_qrcode();
        if( isApiOk( data ) )
        {
            if( data.data.qr_url )
            {
                this.setState({"qr_url":data.data.qr_url,"token":data.data.token});
                this.props.store.token = data.data.token;
            }
        }
        else showApiError( data );
    }
    
    render()
    {
        const left = <Button minimal={true} icon={<Icon.ArrowLeft />} onClick={()=>this.props.history.replace("/settings")} />;

        const main = <div className="fox-login-page">
        <div className="header underline">
            <div className="left">
            {left}
            </div>
            <div className="center">
            微信登入
            </div>
            <div className="right">
            </div>
        </div>
        <div className="form">
            { this.state.qr_url && <div className="qrbox">
                <div className="title">
                    扫码关注后自动登入
                </div>
                <div className="image">
                    <img src={this.state.qr_url} />
                </div>
                <div className="footer">
                    微信中可以长按识别、手机上可以截屏后用微信扫描相册截图。
                </div>
                <div className="actionbar">
                    <div className="continue">
                        <Button text="我已扫码" intent="primary" large={true} onClick={()=>this.check_qrcode()}/>
                    </div>
                    <div className="reload">
                        <Button text="过期刷新" className="outline" minimal={true} large={true} onClick={()=>this.load_qrcode()}/>
                    </div>
                </div>
            </div> }
        </div>
        </div>
        
        return main;
    }
} 