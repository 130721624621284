import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import * as Icon from 'react-feather';
import { Button } from '@blueprintjs/core';
import DictItem from '../component/DictItem'; 
import { toast } from '../util/Function';
import ReactFileReader from 'react-file-reader';

@withRouter
@inject("store")
@observer
export default class DictList extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    state = {"dict_list":[]};
    
    componentDidMount()
    {
       this.load_dict(); 
    }

    async load_dict()
    {
        const { data } = await this.props.store.load_dict();
        if( data.dicts && data.dicts[0] )
        {
            let all_dicts = data.dicts;
            this.props.store.local_dicts.forEach( dict =>
            {
                all_dicts.unshift( {
                    "id":dict,
                    "name":dict,
                    "r15":0,
                    "local":1
                } )
            });
            this.setState({"dict_list":all_dicts});
            this.props.store.mlog( 'dict_loaded' );
        }
        
    }
    async add_dict()
    {
        if( !window.require ) return false;
        const filters = [{"name":"福利单词词库","extensions":["json"]}];



        dialog.showOpenDialog( {filters} , (fileNames) => {
            // fileNames is an array that contains all the selected
            if(fileNames === undefined){
                console.log("No file selected");
                return;
            }

            // console.log( path.basename(fileNames[0]) );
        
            // this.props.store.openFile( fileNames );
            fs.readFile(fileNames[0], async (err, data) => 
            {
                if(err){
                    alert("An error ocurred reading the file :" + err.message);
                    return;
                }

                const jsondata = JSON.parse( data );
                if( jsondata )
                {
                    // 数据和文件都正确
                    const dictname = path.basename(fileNames[0]).split(".")[0];

                    if( dictname.split("-").length != 2 )
                    {
                        alert("错误词库命名，请将文件命名为xxx-yyy.json"); 
                        return false;
                    }

                    if( this.props.store.local_dicts.includes( dictname ) )
                    {
                        alert("已有同名词库，请改名或者删除后再添加。");

                        return false;
                    }

                    if( await this.props.store.dict_init( dictname , jsondata ) )
                    {
                        // 将词库名称添加到本地
                        let local_dicts = this.props.store.local_dicts;
                        local_dicts.push( dictname );
                        this.props.store.setSettings( "local_dicts", local_dicts);

                        toast("导入完成");
                        this.load_dict();
                    }
                    else
                    {
                        alert("导入失败请稍后再试");
                    }

                    
                } 
            });
        } );

    }

    
    async add( files )
    {
        
        const dictname = files[0].name.split(".")[0];
        
        if( dictname.split("-").length != 2 )
        {
            toast("错误词库命名，请将文件命名为xxx-yyy.json"); 
            return false;
        }

        if( this.props.store.local_dicts.includes( dictname ) )
        {
            toast("已有同名词库，请改名或者删除后再添加。");
            return false;
        }

        // const blob = new Blob([files[0]],{type:'application/json'});
        // const jsondata = JSON.parse(await blob.text());

        var reader = new FileReader();
        reader.onload = async ( e ) =>{
            const jsondata = JSON.parse(e.target.result);
            if( !jsondata )
            {
                toast("词库文件不能被正确解析，请检查格式重新上传");
                return false;
            }

            // console.log( "file read data " ,   jsondata );
            

            if( await this.props.store.dict_init( dictname , jsondata ) )
            {
                // 将词库名称添加到本地
                let local_dicts = this.props.store.local_dicts;
                local_dicts.push( dictname );
                this.props.store.setSettings( "local_dicts", local_dicts);

                toast("导入完成");
                this.load_dict();
            }
            else
            {
                toast("导入失败请稍后再试");
            }

        };
        reader.readAsText(files[0]);
    }

    render()
    {
        const left = <Button minimal={true} icon={<Icon.ArrowLeft />} onClick={()=>this.props.history.push("/")}/>;
        const right = <ReactFileReader fileTypes={["application/JSON"]} handleFiles={(files)=>this.add(files)}><Button minimal={true} icon={<Icon.Plus />}/></ReactFileReader>;

        const main = <div className="fox-dict-page">
            <div className="header underline">
                <div className="left">
                {left}
                </div>
                <div className="center">
                词库
                </div>
                <div className="right">
                {right}
                </div>
            </div>
            <ul className="dict-list">
            { this.state.dict_list && this.state.dict_list.map( item => <DictItem  onUpdated={()=>this.load_dict()} key={item.id} data={item}/> ) }
            </ul>
        </div>;
        return main;
    }
}