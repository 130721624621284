import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import * as Icon from 'react-feather';
import keycode from 'keycode';
import MyButton from '../component/MyButton'; 
import equal from 'fast-deep-equal';

  


@withRouter
@inject("store")
@observer
export default class FoxKeyBoard extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    state = {"keys":'abcdefghijklmnopqrstuvwxyz'.split("")}
    
    componentDidMount()
    {
       this.setKeys(); 
    }

    componentDidUpdate(prevProps)
    {
        if(!equal(this.props.answer, prevProps.answer))
        {
            this.setKeys();
        } 
    }

    setKeys()
    {
        if( !this.props.answer ) return false;
        
        // 将 answer 变成数组
        let ch_array = this.props.answer.split("");

        //  数组排重
        ch_array = ch_array.filter( (value, index, self) => { 
            return self.indexOf(value) === index;
        } );

        // 不足15的单词补全长度
        if( ch_array.length < 15 )
        {
            const len = 15 - ch_array.length;

            for( let i = 0; i < len ; i++ )
            {
                // 添加一个随机字母
                let rand_chr = 'abcdefghijklmnopqrstuvwxyz'.charAt(Math.floor(Math.random() * 26));
                
                do
                {
                    rand_chr = 'abcdefghijklmnopqrstuvwxyz'.charAt(Math.floor(Math.random() * 26));
                }while( ch_array.includes( rand_chr ) )
                
                ch_array.push( rand_chr );  
            }
        }

        // 随机顺序
        // shuffle( ch_array );
        // 按字母顺序排序
        if( this.props.store.keyboard_type === 'qwe' )
        {
            const qwe_string = 'qwertyuiopasdfghjklzxcvbnm';
            ch_array = ch_array.sort( ( a , b ) => qwe_string.indexOf(a) - qwe_string.indexOf(b) );
        }
        else
        {
            ch_array = ch_array.sort( ( a , b ) => a.charCodeAt(0) - b.charCodeAt(0) );
        }
        

        this.setState( {"keys":ch_array} );

    }

    
    onPress( key )
    {
        let e = {};
        if( key === '←' )
        {
            e.key = 'backspace';
            e.keyCode = 8;
        }
        else
        {
            e.key = key;
            e.keyCode = keycode(key);
        }
        
        this.props.store.event.emit('float-keydown',e);
    }
    
    render()
    {
        // 根据 answer 生成 5*3 键盘。 
        const char_array = this.state.keys;


        let count = 1;
        
        return <div className="panel">
            
            { this.props.store.keyboard_layout == 'mini' ? <div className={"key-row "+this.props.store.keyboard_layout}><center>
            {  char_array && Array.isArray( char_array ) && char_array.map( item =>
            {
                return <Button key={count++} className="keybtn" text={item} onClick={()=>this.onPress(item+'')} large={true} />
            }) }

            </center></div> 
            : 
            
            <div className="key-row-narrow">
            { [ "qwertyuiop".split("") , "asdfghjkl".split("") , "-zxcvbnm←".split("") ].map( line =>
            {
                return <div className="line" key={line}>
                    { line.map( item => <Button key={count++} className="keybtn narrow" text={item} onClick={()=>this.onPress(item+'')} large={true} />) }
                </div>;
            }) }
            </div>}
            
            
            
            <div className="action-bar">
                <Button large={true} minimal={true} onClick={()=>this.onPress('Tab')}  icon={<Icon.Crosshair/>} />
                <Button large={true} onClick={()=>this.onPress('ArrowRight')} minimal={true} icon={<Icon.ArrowRightCircle/>} />
                <Button large={true} minimal={true} icon={<Icon.ArrowLeft onClick={()=>this.onPress('Backspace')} />}  />
            </div>

        </div>;
    }
}