import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import WordBox from '../component/WordBox'; 
import { Button } from '@blueprintjs/core';
import * as Icon from 'react-feather';
import HelpBox from '../component/HelpBox'; 
import LoadingPage from '../component/LoadingPage'; 
import keycode from 'keycode';
import { toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class RememberWord extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    state = {"theword":false}
    
    componentDidMount()
    {
        this.init();
    }

    async init()
    {
        const { params } = this.props.match;
        if( params.action && params.args  )
        {
            if( params.action == 'ref')
            {
                const ref_uid = parseInt(params.args);
                if( ref_uid > 0 && ref_uid != this.props.store.uid )
                {
                    await this.props.store.mlog( 'ref_by' , ref_uid );
                    window.location = "/";
                    return false;
                }
            }
        }
        
        await this.load_word();
    }

    async load_word( id = 1 )
    {
        const word = await this.props.store.getWordInfoViaDb( this.props.history );
        // console.log( "loaded word" , word );
        // const word = await this.props.store.getWordInfo( id );
        if( word )
        {
            this.setState({"theword":word});
            
            if( this.props.store.first_guide === 'on' )
            {
                // 新用户默认改成不支持单词音了，所以这里不用设置了
                //this.props.store.word_sound = 'off';
                this.props.store.setSettings('first_guide','off');
                const characters =  word.word.split("");
                for( let tt = 0 ; tt < characters.length-1 ; tt++  )
                {
                    window.setTimeout( ()=>this.props.store.event.emit( 'float-keydown' , {"key":characters[tt],"keyCode":keycode(characters[tt])} ) , 500+500*tt );
                }

                window.setTimeout( ()=>{this.props.store.event.emit( 'float-keydown' , {"key":characters[characters.length-1],"keyCode":keycode(characters[characters.length-1])} );
                // this.props.store.word_sound = 'on';
                } , 500+500*(characters.length-1)+500 );
                
            }
            
        }
        else
        {
            // 没有单词和没有要背的单词是不同的
            if( await this.props.store.dict_count( this.props.store.dict ) > 0 )
            {
                if( window.confirm( "本词库没有需要记忆的单词了，切换词库？" ) )
                {
                    if( this.props.store.shop_version && window.require )
                    {
                        window.location = window.location.pathname+"/#/dict";
                    }
                    else
                    {
                        window.location = "/#/dict";
                    }
                    
                }
            }
            
            
            //toast("目前没有需要记忆的单词了");
            
            // 获取单词失败，有可能是因为数据表正在创建
        }    
        // console.log(theword); 
    }

    async onWordError()
    {
        console.log( "type error" , this.state.theword );
        await this.props.store.markWordErrorViaDb( this.state.theword.id );
    }

    async onNext( errored = false )
    {
        // console.log( 'page errored' , errored );
        await this.props.store.markWordViaDb( this.state.theword.id , errored );

        // 是否取下一个词
        if( this.props.store.jump === "自动" ) return await this.getNextWord();
        
        
        
        
    }

    async getNextWord()
    {
        // this.props.store.add_sync_word( this.state.theword.id );
        
        const word = await this.props.store.getWordInfoViaDb( this.props.history );
        // console.log( word );
        if( word )
        {
            if( word.id )
            window.setTimeout( ()=>
            {
                this.setState({"theword":word});
            }, this.props.store.wait );
        }
        else
        {
            console.log("here");
            if( !this.props.store.limit_executed )
            {
                if( await this.props.store.dict_count( this.props.store.dict ) > 0 )
                {
                    window.setTimeout( ()=>
                    {
                        window.alert("这个词库的单词都已背完啦🦊，点左上角切换词库");
                    }, 2500 );
                }
            }
            
        }
    }

    render()
    {
        if( !this.state.theword ) return <LoadingPage/>;
        const left = this.props.store.level >0 ?<Button minimal={true} icon={<Icon.Book />} onClick={()=>this.props.history.push("/dict")} />:<div></div>;

        const right = <>
        <Button minimal={true} icon={<Icon.Settings  />} onClick={()=>this.props.history.push("/settings")}/>
        </>;
        /*
        
        { this.props.store.sound ? <Button minimal={true} icon={<Icon.Volume2/> } onClick={()=>this.props.store.setSettings('sound',!this.props.store.sound)}   /> : <Button minimal={true} icon={<Icon.VolumeX/> } onClick={()=>this.props.store.setSettings('sound',!this.props.store.sound)}   /> }
        */
        
        const main = <><div className="fox-word-page">
            <WordBox
            leftIcon={left}
            rightIcon={right}
            wordInfo={this.state.theword}
            onNext={(errored)=>this.onNext(errored)}
            onGoNext={(errored)=>this.getNextWord(errored)}
            onWordError={()=>this.onWordError()}
            />
            
        </div>
        {/* <HelpBox/> */}
        </>;
        
        return main;
    }
}