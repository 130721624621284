import AppToaster from './Toaster';
import { sprintf } from 'sprintf-js';

export function toast( string ) {
    AppToaster.show({ "message": string});
}

export function showApiError( data )
{
    if( data && data.code && parseInt( data.code , 10 ) !== 0 )
    {
        if( data.args )
            toast( sprintf( data.info  , ...data.args) );
        else
            toast( data.message );   
            
        if( parseInt( data.code , 10 ) === 40301 )
        {
            if(window.confirm( "未登入或权限不足，要转向登入页面吗？" ))
            {
                window.location = '/#login';
            }
        }
            

        return true;    
    }
    else
        return false;
}

export function isApiOk( data )
{
    return parseInt( data.code , 10 ) === 0;
}

export function isWechat()
{
    return navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger";
}