import { observable, action } from "mobx";
import EventEmitter from 'eventemitter3';
import axios from 'axios';
import dayjs from 'dayjs';
import AsyncNedb from 'nedb-async';
import mobile from 'is-mobile';
import { v4 as uuidv4 } from 'uuid';
import { toast, isApiOk, showApiError } from "../util/Function";
// import { connection } from '../util/jsstore_con';
const API_BASE = process.env.REACT_APP_API_BASE;
import isElectron from 'is-electron';

class AppState
{
    @observable appname = "福利单词Pro";
    
    @observable token = null;

    // user info
    @observable uid = 0;
    @observable level = 0;
    @observable nickname = "";
    @observable avatar = ""; 
    @observable expire_date = ""; 

    
    @observable dict = "cet4-cute";
    @observable wait = 2000;
    @observable jump = "自动"; // 自动 or 手动 手动是会员功能
    @observable sound = "off";
    @observable word_sound = "off"; // 单词音是会员功能
    @observable first_guide = "on";
    @observable push_guide = "on";
    @observable first_error = "on";
    @observable first_fav = "on";
    @observable ten_fav = "on";
    @observable word_to_share = "on"; // 累计某个次数后触发分享

    @observable daily_push = "on";
    @observable keyboard_layout = "full"; // mini or full
    @observable keyboard_type = "alphabeta"; // qwe or alphabeta
    @observable words_order = "无"; // 无 or 记忆曲线 记忆曲线是会员功能
    @observable ten_to_login= "on";
    @observable local_dicts= [];

    @observable dbcompact = false;
    
    guest_tips = [ 
        '⌨️ 记得输入完整的单词，开头和结尾的字母不能省略呀',
        '📱 单手操作时可以在⚙️设置中切换为迷你键盘' , 
        '⌨️ Tab键可以切换释义的显示，键盘最下方的辅助按钮可以「显示释义」、「跳过」和「删除」字符' 
    ];
    free_tips = [ '📒 点击左上角的字典图标可以切换词库'  ];
    
    member_tips = [ 
        '📒 点击左上角的字典图标可以切换词库',
        '⚙️ 打开「按键音」和「单词音」记忆效果更好' , 
        '⚙️ 打开「智能复习」，可以按记忆曲线分8次迭代抗遗忘' , 
        '⚙️ 「手动跳转」就不会错过图片了' , 
        '⌨️ 手动跳转时，除了点箭头图标，还可以按空格触发',
        '点击图片上的 ♥ 可以收藏起来慢慢看',
        ' ♥ 旁边有分享按钮可以分享给朋友',
        '⌨️ 按键盘1和点击图片上的 ♥ 效果一样',
        '☁ 在其他电脑和手机上想继续背词，可以把进度上传到云端'  
    ];

    shop_tips = [ 
        '⌨️ 记得输入完整的单词，开头和结尾的字母不能省略呀',
        '📒 点击左上角的字典图标可以切换词库',
        '⚙️ 打开「按键音」和「单词音」记忆效果更好' , 
        '⚙️ 打开「智能复习」，可以按记忆曲线分8次迭代抗遗忘' , 
        '⚙️ 「手动跳转」就不会错过图片了' , 
        '⌨️ 手动跳转时，除了点箭头图标，还可以按空格触发',
        '点击图片上的 ♥ 可以收藏起来慢慢看',
        '⌨️ 按键盘1和点击图片上的 ♥ 效果一样'  
    ];
    words_count = 1;

    limit_executed = false;
    guest_words_limit = 30;
    free_words_limit = 100;
    fav_to_share_count = 5; // 累积收藏次数触发分享
    word_to_share_count = 50; // 累积次数触发分享
    push_guide_count = 40; // 累积次数触发分享
    // words_to_sync_count = 2;

    words_to_sync = [];
    uuid = false;

    @observable keyboard_open = "on";
    shop_version = false;
    app_version = '20200324';

    fields_to_save = ['uuid','dict','wait','sound','word_sound','keyboard_type','keyboard_layout','first_guide','push_guide','first_error','first_fav','ten_fav','jump','words_order','ten_to_login','guest_tips','free_tips','member_tips' , 'shop_tips','word_to_share','daily_push','local_dicts'];

    @action
    set_user( user )
    {
        this.uid = user.id;
        this.level = user.level;
        this.nickname = user.nickname;
        this.avatar = user.avatar;
        this.token = user.token;
        this.expire_date = user.expire_date;
        this.daily_push = parseInt(user.daily_push_open) > 0 ? "on" : "off" ;
        
        // 游客设置重置
        if( this.leve == 0 )
        {
            this.wait = 1500; // 游客不能设置慢跳转
            this.jump = "自动"; // 自动 or 手动
            this.word_sound = "off";
            this.words_order = "无"; // 无 or 记忆曲线
            this.daily_push = "off"; // 游客推送关掉
        }

        // 免费会员设置重置
        if( this.leve == 1 )
        {
            this.jump = "自动"; // 自动 or 手动
            this.word_sound = "off";
            this.words_order = "无"; // 无 or 记忆曲线
        }

        window.localStorage.setItem( "FOXUSER" , JSON.stringify( user ) );

        
    }

    @action
    load_user()
    {
        let data = false;
        const data_json = window.localStorage.getItem( "FOXUSER" );
        if( data_json ) data = JSON.parse( data_json );
        if( data ) this.set_user( data );
    }

    @action
    clean_user()
    {
        window.localStorage.removeItem("FOXUSER");
        this.uid = 0;
        this.level = 0;
        this.nickname = "";
        this.avatar = "";
        this.token = "";
        this.expire_date = "";
        this.daily_push = "off";
    }

    @action async update_user()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        const { data } =  await axios.post( API_BASE + 'userinfo' , params );
        // console.log( data.data.user );
        if( data.data && data.data.user )
        {
            this.set_user( data.data.user );
        }
    }


    async logout()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        
        return await axios.post( API_BASE + 'force_logout' , params );
    }

    async check_qrcode()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        
        return await axios.post( API_BASE + 'sso/check' , params );
    }

    async load_qrcode()
    {
        // console.log(  process.env );
        
        var params = new URLSearchParams();
        return await axios.get( API_BASE + 'user/signin' );
    }

    async get_pay_info( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        
        return await axios.post( API_BASE + 'prepay/info' , params );
    }


    async load_sales()
    {
        var params = new URLSearchParams();
        return await axios.get( API_BASE + 'sales' );
     
    }

    thelocation( path )
    {
        // 根据环境进行不同的跳转
        
        // file path
        if( window.location.protocol == 'file:' )
        {
            console.log( 'file path' , window.location.pathname +"#"+ path  );
            return window.location.pathname +"#"+ path;
        }
        else
        {
            // web
            console.log( 'web path ' ,  path   );
            return path;
        }
    }
    
    constructor()
    {
        // 首先干掉http域名全部跳往https
        // if( window.location.protocol != 'https' )
        // {
        //     if( window.location.hostname == 'fox.ftqq.com' )
        //     {
        //         window.location = 'https://fox.ftqq.com';
        //         return false;
        //     } 
        // }
        // eletron下不检测
        if( window.location.protocol === 'http:' )
        {
            
            if( window.location.hostname == 'fox.ftqq.com' )
            {
                if( window.confirm("你正在使用http协议，是否跳转为更安全的https") )
                {
                    window.location = 'https://fox.ftqq.com';
                    return false;
                }
            }
           
        }
        
        
        
        this.load_data();
        this.load_user();
        if( this.shop_version )
        {
            // 关掉所有的引导
            this.level = 2;
            this.fav_to_share_count = 10000000; 
            this.word_to_share_count = 10000000; // 累积次数触发分享
            this.push_guide_count = 10000000; // 累积次数触发分享
        } 

        // electron下关闭键盘
        if( window.require )
        {
            this.keyboard_open = 'off';
        } 

        // 如果不存在uuid，生成一个
        if( !this.uuid )
        {
            this.setSettings( 'uuid' , uuidv4() );
            this.mlog( 'user_init' );
        }
        
        
        this.event = new EventEmitter();
        
        this.db = new AsyncNedb({"filename":"foxnedb","autoload":true,});
        this.db_init();
        
    }

    @action 
    async setSettings( field , value , history = null )
    {
        // 商店版，直接生效
        if( this.shop_version )
        {
            this[field] = value;
            this.save_data();
            return true;
        }
        
        // fox会员用设置
        if( ['word_sound','words_order','jump'].includes( field ) )
        {
            if( this.level == 0 )
            {
                toast("请登入后设置");
                if( history ) history.push("/login");
                return false;
            }
            
            if( this.level == 1 )
            {
                toast("此为Fox会员专属功能，订阅后即可使用");
                await this.mlog( 'setting_to_buy' , field );
                if( history ) history.push("/buy");
                return false;
            }
        }

        // 游客限制选项
        if( ['wait','daily_push'].includes( field ) )
        {
            if( this.level == 0 )
            {
                toast("请登入后设置");
                await this.mlog( 'setting_to_login' , field );
                if( history ) history.push("/login");
                return false;
            }
            
        }

        if( field == 'daily_push' )
        {
            // 上传到服务器
            if( !await this.toggle_daily_push( value, history ) ) return false ;
        }
        
        this[field] = value;
        this.save_data();

        if( ['word_sound','words_order','jump'].includes( field ) ){
            await this.mlog( 'member_settings' , [field,value] ); 
        }
    }

    async toggle_daily_push( value, history = null )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("value" , value = value == 'on' ? 1 : 0 );
        const { data } =  await axios.post( API_BASE + 'toggle_daily_push' , params );

        if( !this.check_data_for_login( data, history ) ) return  false;
        
        return data.data && data.data.done && data.data.done == 1;
    }

    check_data_for_login( data, history )
    {
        if( data.code == '40301' )
        {
            if( !history )
            {
                toast("需要登入才能设置，已登入用户可能已过期，请手工退出后重新登入");
                return false;
            }
            
            
            if( window.confirm("需要登入才能设置，是否转向登入界面？") )
            {
                console.log( history );
                if( history ) history.replace("/login");
                return  false;
            }
        }
        
        return true;
    }

    save_data()
    {
        let saveobject = {};
        this.fields_to_save.forEach( item =>
        {
            saveobject[item] = this[item];
        });
        window.localStorage.setItem( 'FOX_DATA' , JSON.stringify( saveobject ) );
    }

    @action 
    load_data()
    {
        const saveobject = JSON.parse( window.localStorage.getItem('FOX_DATA') );
        if( saveobject )
        {
            this.fields_to_save.forEach( item =>
            {
                if( saveobject[item] && (saveobject[item].length > 0  || Array.isArray( saveobject[item] ) ))
                    this[item] = saveobject[item] ;
            });
        }
    }

    @action 
    async set_dict( dictname )
    {
        this.dict = dictname;
        this.save_data();
        console.log("修改词库",this.dict);
        //alert("start reload");
        window.location = this.thelocation("/");
        window.location.reload(true);
    }

    async load_dict()
    {
        // 统一采用简版词库
        return await axios.get('https://foxapi.ftqq.com/dict/shop.json');
        //return this.shop_version ? await axios.get('dict/shop.json') : await axios.get('dict/list.json');
    }

    async db_empty()
    {
        await this.db.asyncRemove({},{ "multi": true });
        window.location = this.thelocation("/");
        window.location.reload(true);
    }

    async dict_remove( dict )
    {
        return await this.db.asyncRemove({"dict":dict},{ "multi": true });
    }

    async db_init()
    {
        // console.log( this.db );
        await this.db.asyncEnsureIndex( {"fieldName":"dict"} );
        await this.db.asyncEnsureIndex( {"fieldName":"level"} );
        await this.db.asyncEnsureIndex( {"fieldName":"word"} );
        await this.db.asyncEnsureIndex( {"fieldName":"last_success_at"} );

        // 检查 dict 对应的数据文档是否存在，不存在的话，通过json导入
        if( await this.dict_init( this.dict ))
        {
            //alert("start reload");
            window.location = this.thelocation("/");
            window.location.reload(true);
        } 

    }

    async dict_init( dictname, dictdata = null )
    {
        // 没有当前词库的单词，开始初始化
        const word = await this.db.asyncFindOne({"dict":dictname});
        if( !word )
        {
            const info = dictname.split("-");
            let recs = [];
            
            if( !dictdata )
            {
                console.log("读取json初始化"+dictname);
                
            
                let { data } = await axios.get('https://foxapi.ftqq.com/dict/dictlist/' + info[0]+ '/' + info[1] + '.json');

                console.log("await 读取完成");
                
                dictdata = data;
            }

            console.log("数据载入完成" );

            if( dictdata )
            {
                console.log("开始添加到db" );
                // 将数据库初始化时间设置到10年前
                const init_time = dayjs().subtract(10,'year').unix();
                
                // let theid = rec_count + 1 ;
                dictdata.forEach( item => {
                    let o = {...item};
                    recs.push( { ...o,"last_success_at":init_time,"level":0,"dict":dictname,"id":dictname+'-'+o.id,"favorite":0,"last_favorite_at":init_time} )    
                } );

                //console.log("recs" , recs);
                const ret = await  this.db.asyncInsert( recs );
                console.log("添加完成" , ret );
                return ret;
            }

        }
        else
        {
            console.log("已有数据，跳过 "+dictname, word);
            // if( dictdata ) return true;
        }
        return false;
    }

    async get_fav_list( skip = 0 , limit = 30 )
    {
        return await this.db.asyncFind( {"favorite":1} , [['skip',skip],['limit',limit],["sort",{"last_success_at":-1}]] );
    }

    async get_image_by_id( id = null )
    {
        if( !id )
        {
            // 产生一个随机id
            const records = await this.db.asyncFind( {"dict":this.dict} , {"id":1} );
            const all_ids = records.map( item => item.id );
            if( all_ids )
            {
                const key = Math.ceil(Math.random() * all_ids.length);
                id = all_ids[key];
            }
        }
        
        return await this.db.asyncFindOne( {"id":id} );
    }

    async favorite_word( id , value = 1 )
    {
        let worddoc = await this.db.asyncFindOne( {"id":id} );
        if( !worddoc )
        {
            console.log("word",id);
            toast("没有找到对应的单词");
            return false;
        }
        worddoc.favorite = value;
        worddoc.last_favorite_at = dayjs().unix();

        const ret = await this.db.asyncUpdate( {"id":id} , worddoc );
        return ret ? worddoc : false ;
    }

    async get_fav_count()
    {
        return await this.db.asyncCount( {"favorite":1} );
    }
    
    
    // 从数据库中取出需要背的下一个单词
    async getWordInfoViaDb( history = null )
    {
        let ret;
        
        // 检查游客和免费会员的配额
        if( this.level < 2 )
        {
            const limit = this.level == 1 ? this.free_words_limit : this.guest_words_limit;
            
            // 这里的ret是统计从今天零点开始背过的单词数量
            ret = await this.db.asyncCount( {"level":{"$gt":0},"last_success_at":{ "$gt" : dayjs(dayjs().format('YYYY-MM-DD 00:00:00')).unix() }} );

            // 游客在背过10个单词后触发登入引导
            if(this.level == 0 && ret >= 10 && this.ten_to_login == 'on' )
            {
                this.setSettings( "ten_to_login" , "off" );
                toast("恭喜你解锁了新的词库");
                await this.mlog( 'word_10' );
                if( history ) history.push("/guide/login"); 
                return true;
            }

            if( ret >= limit )
            {
                this.limit_executed = true;
                if( this.level < 1 )
                {
                    toast("游客的背词数量已经到达上限，请登入后继续");
                    await this.mlog( 'guest_max_per_day' );
                    if( history ) history.push("/login");
                    return false;
                }
                else
                {
                    toast("免费用户每天最多背"+this.free_words_limit+"个词，可开通订阅或明天继续");
                    await this.mlog( 'free_max_per_day' );
                    if( history ) history.push("/buy");
                    return false;
                }  
            }
            console.log( "limit" , limit , "ret" , ret );
        }
        else
        {
            this.limit_executed = false;
        }

        // console.log( "level" , this.level , this.member_tips );

        this.words_count++;
        
        // 显示tips
        if( this.level == 0 && this.guest_tips.length > 0 )
        {
            if( this.words_count%3 == 0 )
            {
                const tips = this.guest_tips.shift();
                this.save_data();
                toast(tips);
            }
            
        }

        if( this.level == 1 && this.free_tips.length > 0 )
        {
            if( this.words_count%3 == 0 )
            {
                const tips = this.free_tips.shift();
                this.save_data();
                toast(tips);
            }
        }

        if( this.level == 2  )
        {
            
            if( this.shop_version )
            {
                if( this.shop_tips.length > 0 )
                {
                    if( this.words_count%3 == 0 )
                    {
                        const tips = this.shop_tips.shift();
                        this.save_data();
                        toast(tips);
                    }
                }
            }
            else
            {
                if( this.member_tips.length > 0 )
                {
                    if( this.words_count%3 == 0 )
                    {
                        const tips = this.member_tips.shift();
                        this.save_data();
                        toast(tips);
                    }
                }
            }
            
            
            
        }

        // 从这里开始 ret 是累积背词数量
        ret = await this.db.asyncCount( {"level":{"$gt":0} });

        if( this.level > 0 && this.push_guide !== "off" && this.daily_push != 'on' )
        {
            if( ret && ret >= this.push_guide_count )
            {
                this.setSettings( 'push_guide' , 'off' );
                if( window.confirm( "🕗 背单词最难坚持，开启每日微信提醒吗？可随时在 ⚙️ 设置中调整" ) )
                {
                    await this.setSettings( 'daily_push' , 'on' );
                }
                else
                {
                    toast("你可以随时在手动在 ⚙️ 设置 中开启");
                }
            }
            
        }

        if( this.word_to_share !== "off" )
        {
            // 这里又要多查一次
            // ret = await this.db.asyncCount( {"level":{"$gt":0} });
            if( ret && ret >= this.word_to_share_count )
            {
                this.setSettings( 'word_to_share' , 'off' );
                if( history && window.confirm( "你已经背了"+ this.word_to_share_count +"个词了，能不能支持我们下发个分享？🙇🏻‍♂️" ))
                {
                    //toast("已经收藏了"+ fav_count +"张图了，好东西试着分享给朋友？");
                    await this.mlog( 'word_to_share_visit' );
                    history.push("/share");
                }
            }
        }
        
        
        
        
        if( this.words_order === "无" )
        {
            // 从没背的单词中照片一个就好
            ret = await this.db.asyncFindOne( {"dict":this.dict,"level":0} );

            if( ret ) return ret;
        }
        
        ret = await this.db.asyncFindOne( {"dict":this.dict,"level":8,"last_success_at":{ "$lt" : dayjs().subtract(15,'day').unix() }} , [["sort",{"last_success_at":-1}]] );

        if( ret ) return ret;

        ret = await this.db.asyncFindOne( {"dict":this.dict,"level":7,"last_success_at":{ "$lt" : dayjs().subtract(7,'day').unix() }} , [["sort",{"last_success_at":-1}]] );

        if( ret ) return ret;

        ret = await this.db.asyncFindOne( {"dict":this.dict,"level":6,"last_success_at":{ "$lt" : dayjs().subtract(4,'day').unix() }} , [["sort",{"last_success_at":-1}]] );

        if( ret ) return ret;

        ret = await this.db.asyncFindOne( {"dict":this.dict,"level":5,"last_success_at":{ "$lt" : dayjs().subtract(2,'day').unix() }} , [["sort",{"last_success_at":-1}]] );

        if( ret ) return ret;

        ret = await this.db.asyncFindOne( {"dict":this.dict,"level":4,"last_success_at":{ "$lt" : dayjs().subtract(24,'hour').unix() }} , [["sort",{"last_success_at":-1}]] );

        if( ret ) return ret;

        ret = await this.db.asyncFindOne( {"dict":this.dict,"level":3,"last_success_at":{ "$lt" : dayjs().subtract(12,'hour').unix() }} , [["sort",{"last_success_at":-1}]] );

        if( ret ) return ret;

        ret = await this.db.asyncFindOne( {"dict":this.dict,"level":2,"last_success_at":{ "$lt" : dayjs().subtract(30,'minute').unix() }} , [["sort",{"last_success_at":-1}]] );

        if( ret ) return ret;

        ret = await this.db.asyncFindOne( {"dict":this.dict,"level":1,"last_success_at":{ "$lt" : dayjs().subtract(5,'minute').unix() }} , [["sort",{"last_success_at":-1}]] );

        if( ret ) return ret;

        
        ret = await this.db.asyncFindOne( {"dict":this.dict,"level":0} );
        if( ret ) return ret;
        return false;
    }

    
    async markWordViaDb( id , errored = false )
    {
        // console.log( "errored" , errored );
        // 如果在输入时出过错，那么等级调整为1
        // 否则等级+1
        let worddoc = await this.db.asyncFindOne( {"id":id} );
        
        if( errored ) worddoc.level = 1;
        else worddoc.level++;

        if( errored ) worddoc.last_success_at = dayjs().subtract('4','minute').unix();
        else worddoc.last_success_at = dayjs().unix();


        const ret = await this.db.asyncUpdate( {"id":id} , worddoc );
        console.log( "update" , ret );

        return ret;
    }

    async dict_count( dictname )
    {
        return await this.db.asyncCount({"dict":dictname});
    }

    async download_words( history = null )
    {
        if( this.level < 2 )
        {
            toast("此为Fox会员专享功能");
            if( history ) history.push("/buy");
            return false;
        }
        
        if( !window.confirm("下载数据将覆盖本地进度，确认继续？") ) return false;

        await this.mlog( 'cloud_download' );
                    
        var params = new URLSearchParams();
        params.append("token" , this.token);
        const { data } =  await axios.post( API_BASE + 'download_words' , params );

        
        if( isApiOk( data ) )
        {
            if( !data.data.words )
            {
                console.log( "加载list错误" , data );
                return false;
            }
            
            toast("🦊正在更新数据，根据单词数量可能需要1~5分钟时间，请稍候");
            
            this.dbcompact = true;
                
            // 要考虑词库不存在的情况
            // 不存在就通过json导入创建
            let dicts = [];
            let i = 0 , j =0 , k= 0;

            /*
            for( let i = 0; i < data.data.words.length; i++)
            {
                let item = dicts[i];
                item.dict = item.id.split("-")[0];
                if( !dicts.includes( item.dict ) ) dicts.push( item.dict );
            }*/
            let ids = [];
            data.data.words.forEach( item =>
            {
                const info = item.id.split("-");
                item.dict = info[0]+'-'+info[1];
                if( !dicts.includes( item.dict ) ) dicts.push( item.dict );
                ids.push( item.id );
            });            

            for( i = 0; i < dicts.length; i++ )
            {
                let thedict = dicts[i];
                console.log( "初始化数据库" , thedict );
                await this.dict_init( thedict ); 
            }

            // 一次性取出匹配上的文档
            const docs = await this.db.asyncFind({ "id" : { "$in" : ids } });
            

            
            
            // 更新本地词库
            for( i = 0; i < data.data.words.length; i++ )
            {
                let item = data.data.words[i];
                //console.log( '云端数据', item );
                if( item.id )
                {
                    // let o = {...item};
                    //console.log( "find item", o.id , await this.db.asyncFindOne( {"id":o.id} ) );
                    // asyncForEach(['level','last_success_at','favorite','last_favorite_at'] , field =>
                    // {
                    //     item[field] = parseInt( item[field] );
                    // });

                    ['level','last_success_at','favorite','last_favorite_at'].forEach( field =>
                    {
                        item[field] = parseInt( item[field] );
                    } );

                    // console.log( "item数据过滤后", item );

                    

                    // let o = await this.db.asyncFindOne( {"id":item.id} );
                    let o = docs.filter( doc => doc.id == item.id )[0];

                    console.log( "取出文档" , o.word );

                    // 如果云端数据修改时间先于本地则不覆盖
                    if( o.last_success_at > item.last_success_at && o.last_favorite_at > item.last_favorite_at )
                    {
                        // 本地最新跳过
                        console.log("本地最新，跳过");
                        continue;
                    }

                    o.level = item.level;
                    o.last_success_at = item.last_success_at;
                    o.favorite = item.favorite;
                    o.last_favorite_at = item.last_favorite_at;

                    // console.log( "新文档" , o );

                    const ret = await this.db.asyncUpdate({"id":item.id} , o );

                    if( !ret )
                    {
                        console.log("更新文档失败",ret);
                    }
                    else
                    {
                        //console.log( "更新后数据", o.id , await this.db.asyncFindOne( {"id":o.id} ) );
                    }
                }
            }
            

            this.dbcompact = false;
            toast("🦊 数据更新完成");

            // console.log("循环完成");
            //console.log( await this.db.asyncFind( {"dict":"cspix"} ) );
            // console.log( await this.db.asyncFindOne( {"id":"cspix-9"} ) ); 
        }
        else showApiError( data );

    }

    async mlog( action , thedata=null )
    {
        if( this.shop_version ) return true;
        var params = new URLSearchParams();
            params.append("token" , this.token);
            params.append("uuid" , this.uuid);
            params.append("action" , action);
            params.append("data" , JSON.stringify( thedata ));
            return await axios.post( API_BASE + 'meta/log' , params );
    }

    async upload_words( history = null )
    {
        if( this.level < 2 )
        {
            toast("此为Fox会员专享功能");
            if( history ) history.push("/buy");
            return false;
        }
        
        if( !window.confirm("上传的数据将覆盖云端的其他进度，确认继续？") ) return false;

        await this.mlog( 'cloud_upload_start' );
        
        const ret = await this.db.asyncFind( {"$or":[ {"level":{"$gt":0}} , {"favorite":"1"} ]} , {"id":1,"word":1,"level":1,"last_success_at":1,"favorite":1,"last_favorite_at":1}  );

        if( ret )
        {
            // 发送同步数据
            var params = new URLSearchParams();
            params.append("token" , this.token);
            params.append("time" , await this.get_db_update_time());
            params.append("data" , JSON.stringify( ret ));
            const { data } =  await axios.post( API_BASE + 'upload_words' , params );

            if( isApiOk( data ) )
            {
                toast("🦊 数据上传完成");
                await this.mlog( 'cloud_upload_success' );
                return data;
            } 
            else showApiError( data );
        }

        toast("🦊 数据上传失败，请稍候重试");

        return false;
    }

    async get_db_update_time()
    {
        const ret =  await this.db.asyncFindOne( {"level":{"$gt":0}} , [["sort",{"last_success_at":-1}]] );
        return ret ? ret.last_success_at : false;
    }

    //全量同步
    async all_words_sync()
    {
        const ret = await this.db.asyncFind( {"$or":[ {"level":{"$gt":0}} , {"favorite":"1"} ]} , {"id":1,"level":1,"last_success_at":1,"favorite":1,"last_favorite_at":1}  );

        if( ret )
        {
            // 发送同步数据
            var params = new URLSearchParams();
            params.append("token" , this.token);
            params.append("time" , await this.get_db_update_time());
            params.append("data" , JSON.stringify( ret ));
            const { data } =  await axios.post( API_BASE + 'sync' , params );

            if( data.data && data.data.client2change )
            {
                // 更新本地词库
                data.data.client2change.forEach( async item =>
                {
                    if( item.id )
                        await this.db.asyncUpdate( item , {"id":item.id} );
                })
            }
        }
    }
    

    @action async add_sync_word( word_id )
    {
        return false;
        this.words_to_sync.push( word_id );
        if( this.words_to_sync.length >= this.words_to_sync_count )
        {
            // 查询
            // // Using $in. $nin is used in the same way
            // db.find({ planet: { $in: ['Earth', 'Jupiter'] }}, function (err, docs) {
            //     // docs contains Earth and Jupiter
            //   });

            console.log( this.words_to_sync );

            const ret = await this.db.asyncFind( {"id":{"$in":this.words_to_sync}} , {"id":1,"level":1,"last_success_at":1,"favorite":1,"last_favorite_at":1} );

            console.log( ret );

            if( ret )
            {
                // 发送同步数据
                var params = new URLSearchParams();
                params.append("token" , this.token);
                params.append("time" , await this.get_db_update_time());
                params.append("data" , JSON.stringify( ret ));
                const { data } =  await axios.post( API_BASE + 'sync' , params );

                if( data.data && data.data.client2change )
                {
                    // 更新本地词库
                    data.data.client2change.forEach( async item =>
                    {
                        if( item.id )
                            await this.db.asyncUpdate( item , {"id":item.id} );
                    })

                    // 清空本地数据
                    this.words_to_sync = [];
                }
                // console.log( data.data );
            }
        }
    }
    
       
}

export default new AppState();