import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

@withRouter
@inject("store")
@observer
export default class PianoScreen extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    state = {"data":[0,10]};
    
    componentDidMount()
    {
        if( this.props.data && Array.isArray( this.props.data ) )
        {
            this.setState({"data":[this.props.data[0],this.props.data[1]]});
        }
    }

    componentDidUpdate(prevProps)
    {
        if (this.props.data !== prevProps.data) 
        {
            // console.log( "changed" , this.props.data  );
            this.setState({"data":this.props.data});
            // if( this.props.data && Array.isArray( this.props.data ) )
            // {
            //     this.setState({"data":[this.props.data[0],this.props.data[1]]});
            // }
        }
    }
    
    render()
    {
        if( !this.props.data ) return null;
        const typed = this.state.data[0];
        const all = this.state.data[1];
        
        const gaps = [];
        for( let i = 1; i <= all; i++ )
        {
            if( i <= typed ) gaps.push('white');
            if( i > typed )
            {
                if( i-1 == typed ) gaps.push('black1');
                else if( i-2 == typed ) gaps.push('black2');
                else gaps.push('black');
            } 
        }

        // console.log( typed , all , "gaps" , gaps );

        
        let count = 1;
        
        
        return <div className="piano" >
          { gaps && gaps.map( gap => <div key={count++} className={"item "+gap}></div> ) }  
        </div>;
    }
}