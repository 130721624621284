import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import * as FTIcon from 'react-feather';
import { Button, Switch, Overlay, ButtonGroup } from '@blueprintjs/core';
import LoadingPage from '../component/LoadingPage'; 
import { toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class Settings extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    componentDidMount()
    {
        if( this.props.store.token )
            this.props.store.update_user();
    }
    compactDb()
    {
        this.props.store.dbcompact = true;
        this.props.store.db.on( 'compaction.done' , ()=>{ this.props.store.dbcompact = false } );
        this.props.store.db.persistence.compactDatafile();
    }

    clearDb()
    {
        if( !window.confirm("清空应用数据后你将丢失现有的单词进度和全部自定义词库，确定继续？") ) return false;

        this.props.store.db_empty();
    }

    async logout()
    {
        // 强制服务器端退出
        const { data } = await this.props.store.logout();
        // 清空本地数据
        this.props.store.clean_user();
        toast("你已经成功退出");
    }

    render()
    {
        const left = <Button minimal={true} icon={<FTIcon.ArrowLeft />} onClick={()=>this.props.history.push("/")}/>;

        const level_class = this.props.store.level > 1 ? ' ': ' gray ';

        const main = <div className="fox-settings-page">
            <div className="header underline">
                <div className="left">
                {left}
                </div>
                <div className="center">
                设置
                </div>
                <div className="right">
                </div>
            </div>
            <div className="form">
                <div className="item">
                    <div className="left">
                    { this.props.store.sound === 'on' ? <FTIcon.Volume2 size={18}/> : <FTIcon.VolumeX size={18}/> }按键音
                    
                    </div>
                    <div className="right">
                    <Switch large={true} alignIndicator="right" checked={this.props.store.sound === 'on'}  onChange={(e)=>this.props.store.setSettings('sound',e.target.checked ? 'on':'off',this.props.history)} />
                    </div>
                </div>
                <div className="item">
                    <div className="left member">
                     { this.props.store.word_sound === 'on' ? <FTIcon.Volume2 size={18}/> : <FTIcon.VolumeX size={18}/> }单词音
                    
                    </div>
                    <div className="right">
                    <Switch large={true} alignIndicator="right" checked={this.props.store.word_sound === 'on'}  onChange={(e)=>this.props.store.setSettings('word_sound',e.target.checked ? 'on':'off',this.props.history)} />
                    </div>
                </div>
                <div className="item">
                    <div className="left member">
                        智能复习
                    </div>
                    <div className="right">
                        <Switch large={true} alignIndicator="right" checked={this.props.store.words_order !== '无' } label={this.props.store.words_order}  onChange={(e)=>this.props.store.setSettings('words_order', this.props.store.words_order === "无" ? "记忆曲线" : "无", this.props.history )} />
                    </div>
                </div>
                <div className="item">
                    <div className="left  member">
                        自动跳转
                    </div>
                    <div className="right">
                        <Switch large={true} alignIndicator="right" checked={this.props.store.jump === "自动" } label={this.props.store.jump}  onChange={(e)=>this.props.store.setSettings('jump', this.props.store.jump === "自动" ? "手动" : "自动", this.props.history )} />
                    </div>
                </div>
                { this.props.store.jump === "自动" && <div className="item">
                    <div className="left">
                        慢跳转等待
                    </div>
                    <div className="right">
                        <Switch large={true} alignIndicator="right" checked={this.props.store.wait !== 2000 } label={parseInt(this.props.store.wait/1000)+"秒"}  onChange={(e)=>this.props.store.setSettings('wait', this.props.store.wait === 2000 ? 3000 : 2000, this.props.history )} />
                    </div>
                </div> }
                

                <div className="item">
                    <div className="left">
                        全键盘
                    </div>
                    <div className="right">
                        <Switch large={true} alignIndicator="right" checked={this.props.store.keyboard_layout !== "mini" } label={this.props.store.keyboard_layout}  onChange={(e)=>this.props.store.setSettings('keyboard_layout', this.props.store.keyboard_layout === "mini" ? "full" : "mini" , this.props.history )} />
                    </div>
                </div>
                {
                    this.props.store.keyboard_layout === 'mini' && <div className="item">
                    <div className="left">
                        按键顺序
                    </div>
                    <div className="right">
                        <Switch large={true} alignIndicator="right" checked={this.props.store.keyboard_type !== "qwe" } label={this.props.store.keyboard_type}  onChange={(e)=>this.props.store.setSettings('keyboard_type', this.props.store.keyboard_type === "qwe" ? "alphabeta" : "qwe" , this.props.history )} />
                    </div>
                </div> 
                }

                <div className="hr"></div>

                { !this.props.store.shop_version &&  <>
                    <div className="item">
                    <div className="left">
                        <FTIcon.User  size={18}/>{this.props.store.nickname}
                    </div>
                    <div className="right">
                        { this.props.store.level > 0 ? <Button minimal={true} onClick={()=>this.logout()}   text="退出" className="outline" icon={<FTIcon.LogOut  size={18}/>} /> : <Button minimal={true} onClick={()=>this.props.history.push("/login")} icon={<FTIcon.LogIn size={18}/>}  text="微信登入" className="outline" />}
                        
                    </div>
                </div>

                <div className="item">
                    <div className={"left "+level_class}>
            <FTIcon.Award  size={18}/>Fox会员 <span className="time">{this.props.store.expire_date}</span>
                    </div>
                    <div className="right">
                    <Button minimal={true} onClick={()=>this.props.history.push("/buy/mem")} icon={<FTIcon.Smile size={18}/>}  text="续费" className="outline" />
                        
                    </div>
                </div>
                </>}
                

                <div className="item">
                    <div className="left member">
                    <FTIcon.Image  size={18}/> 收藏
                    </div>
                    <div className="right">
                        <Button minimal={true} onClick={()=>this.props.history.push("/fav")}  icon={<FTIcon.ArrowRight  size={18}/>} text="查看" className="outline" />
                    </div>
                </div>
                
                
                { !this.props.store.shop_version && <>
                    <div className="hr"></div>
                    <div className="item">
                        <div className="left member">
                            云端进度
                        </div>
                        <div className="right">
                            <ButtonGroup>
                            <Button minimal={true} onClick={()=>this.props.store.upload_words(this.props.history)}  icon={<FTIcon.UploadCloud  size={18}/>} text="上传" className="outline" />
                            <Button minimal={true} onClick={()=>this.props.store.download_words(this.props.history)}  icon={<FTIcon.DownloadCloud  size={18}/>} text="下载" className="outline" />
                            </ButtonGroup>
                        </div>
                    </div>  
                </> }
                
                
                
                
                {/* <div className="item">
                    <div className="left">
                        数据库
                    </div>
                    <div className="right">
                        <Button minimal={true} onClick={()=>this.compactDb()}  icon={<FTIcon.Database  size={18}/>} text="优化" className="outline" />
                    </div>
                </div> */}
                <div className="item">
                    <div className="left">
                        本地数据
                    </div>
                    <div className="right">
                        <Button minimal={true} onClick={()=>this.clearDb()}  icon={<FTIcon.Trash  size={18}/>} className="outline"  text="清空" />
                    </div>
                </div>

                { !this.props.store.shop_version && <><div className="hr"></div>
                <div className="item">
                    <div className="left">
                    <FTIcon.Clock  size={18}/>    微信提醒(每晚8点推送)
                    </div>
                    <div className="right">
                    <Switch large={true} alignIndicator="right" checked={this.props.store.daily_push === "on" } onChange={(e)=>this.props.store.setSettings('daily_push', this.props.store.daily_push === "on" ? "off" : "on" , this.props.history )} />
                        
                    </div>
                </div>
                <div className="hr"></div>

                <div className="item">
                    <div className="left">
                    <FTIcon.MessageSquare size={18}/> 意见反馈
                    </div>
                    <div className="right">
                    <Button minimal={true} onClick={()=>window.open("https://support.qq.com/product/134125","_blank")}  icon={<FTIcon.ArrowRight  size={18}/>} text="进入" className="outline" />

                    </div>
                </div>
                </> }
                
                

                

                <div className="item row">
                    { this.props.store.shop_version ? <div className="desp">
                    你当前使用的版本为：FoxDict 🦊 build vip-{this.props.store.app_version}
                    </div>  : <>
                    <div className="desp">
                    感谢使用福利单词 🦊 Pro版 。本产品可以免费使用，部分高级功能对订阅会员开放，以支持运维和服务器费用。
                    </div> 
                    <div className="inner-hr"/>
                    <div className="desp">
                    标注UGC的图库图片来自用户贡献，如有合作或侵权投诉请通过上边意见反馈页面联系，谢谢。
                    </div>
                    <div className="desp">
                    部分表情图标来自 twemoji.twitter.com 。
                    </div></> }
                    
                </div>
                
            </div>
            <Overlay isOpen={this.props.store.dbcompact}>
                <LoadingPage/>
            </Overlay>
            
        </div>;
        return main;
    }
}

