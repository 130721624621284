import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

@withRouter
@inject("store")
@observer
export default class MyButton extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        return <div className="mybutton bp3-button" onClick={(e)=>{
            if( this.props.onClick ) this.props.onClick(e);
            else console.log( "no click event" , this );
        }}>
            {this.props.icon}<span className="bp3-button-text">{this.props.text}</span>
        </div>;
    }
}