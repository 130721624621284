import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TypeBox from '../component/TypeBox'; 
import FoxKeyBoard from '../component/FoxKeyBoard'; 
import equal from 'fast-deep-equal';
import KeyboardBtn from '../component/KeyboardBtn'; 
import { Button, ButtonGroup, Spinner } from '@blueprintjs/core';
import * as FTIcon from 'react-feather';
import { toast } from '../util/Function';
import PianoScreen from '../component/PianoScreen'; 


@withRouter
@inject("store")
@observer
export default class WordBox extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    state = {
        "word_tips":"",
        "word":"",
        "opacity":0.9,
        "show_mask":true,
        "show_answer":false,
        "success_view":false,
        "bg_url":false,
        "explain":false,
        "favorite":false,
        "sound":false,
        "piano":[0,10]
    }

    componentDidUpdate(prevProps)
    {
        if(!equal(this.props.wordInfo, prevProps.wordInfo))
        {
            console.log( "updated..." );
            this.setState({ "word_tips":"",
            "word":"",
            "opacity":0.9,
            "piano":[0,10],
            "show_mask":true,
            "next_press":false,
            "show_answer":false,
            "success_view":false,
            "success_view":false,
            "favorite":false});
            
            this.set_word_info();
        }
       
    }
    
    componentDidMount()
    {
        this.set_word_info();
    }

    set_word_info( info = null )
    {
        const WEB_DICT_BASE = window.location.origin+'/';

        if( !info ) info = this.props.wordInfo;

        if( info ){
            this.setState({
                "id":info.id,
                "word":info.word,
                "bg_url": info.image,
                "sound": info.sound ?  info.sound :"",
                "explain":info.explain,
                "favorite":info.favorite == 1 ? true : false
            })
            this.set_word_tips( info.word );
        }

    }

    set_word_tips( string )
    {
        let hidden_string_array = [];
        let len = string.length;
        if( len >= 3 )
        {
            hidden_string_array.push( string[0] );
            for( let i = 0 ; i < len-2 ; i++ )
            {
                hidden_string_array.push('_');
            }
            hidden_string_array.push( string[len-1] );
        }
        
        this.setState({"word_tips":hidden_string_array.join(""),"word":string});
    }

    onSuccess( errored )
    {
        this.setState({"opacity":0,"success_view":true});
        // 播放语音
        // 保存进度
        // 然后载入下一个词
        console.log( 'wordbox errored' , errored );
        if( this.props.onNext ) this.props.onNext( errored );
    }

    onGoNext()
    {
        this.setState({"next_press":true});
        if( this.props.onGoNext ) this.props.onGoNext();
    }

    onToggleAnswer()
    {
        this.setState( {"show_answer":!this.state.show_answer} );
    }

    onSetOpacity( op )
    {
        this.setState({"opacity":op});
    }

    onSetPiano( typed , all )
    {
        this.setState({"piano":[typed,all]});
    }

    async favorite( status = 1 )
    {
        const newword = await this.props.store.favorite_word( this.state.id , status );
        const theid = this.state.id+'';
        // console.log( newword );
        if( newword ) this.set_word_info( newword );

        if( this.props.store.first_fav !== 'off' )
        {
            this.props.store.setSettings( 'first_fav' , 'off' );
            toast("收藏后可在 ⚙️ 设置 → 收藏 中欣赏");
        }

        // this.props.store.tenfav( this.props.history );
        if( this.props.store.ten_fav !== 'off' )
        {
            const fav_count = await this.props.store.get_fav_count();
            console.log( "fav count" , fav_count );
            if( fav_count && fav_count >= this.props.store.fav_to_share_count )
            {
                this.props.store.setSettings( 'ten_fav' , 'off' );
                if(window.confirm( "你已经收藏了"+ fav_count +"张图了，分享给朋友，顺便支持我们下呗？🤠" ))
                {
                    //toast("已经收藏了"+ fav_count +"张图了，好东西试着分享给朋友？");
                    await this.props.store.mlog('fav_to_share');
                    this.props.history.push("/share/"+theid);
                }
                
                // toast("已经收藏了"+ fav_count +"张图了，好东西试着分享给朋友？");
                // this.props.history.push("/share/"+this.state.id);
            }
        }
    }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        const bg_style = this.state.bg_url ? {"backgroundImage":`url(${this.state.bg_url})`,"backgroundPosition": "center",
        "backgroundSize": "cover",
        "backgroundRepeat": "no-repeat"} : {};

        let span_count = 1;
        const mask_style = {"opacity":this.state.opacity};
        const tips_string = this.state.show_answer? this.state.word : this.state.word_tips;
        return <div className="fox-word-box" >
            <div className="screen" style={bg_style}>
                
                <div className="over-mask">
                <div className="header">
                    {this.props.leftIcon}
                    {this.props.rightIcon}
                </div>

                <div className="word-area">
                    <div className="word">
                       <TypeBox
                        answer={this.state.word.toLowerCase()}
                        sound={this.state.sound}
                        onSuccess={(errored)=>this.onSuccess(errored)}
                        onWordError={()=>this.props.onWordError()}
                        onSetOpacity={(op)=>this.onSetOpacity(op)}
                        onSetPiano={(typed,all)=>this.onSetPiano(typed,all)}
                        onToggleAnswer={()=>this.onToggleAnswer()}
                        onFavorite={(value)=>this.favorite(value)}
                        onGoNext={()=>this.onGoNext()}
                       />
                        
                    </div>
                    { this.state.success_view ? 
                    ( this.props.store.jump !== "手动" ?
                    <div className="fox-next-button-group">
                    <ButtonGroup>
                        { !this.props.store.shop_version && <Button minimal={true} icon={<span className="bp3-icon"><FTIcon.Share2 size="16" /></span>} className="outline white" onClick={()=>this.props.history.push("/share/"+this.state.id)}/> }
                        

                        
                        { this.state.favorite ? <Button icon="heart" minimal={true} className="outline white" onClick={()=>this.favorite( 0 )}/> :  <Button icon={<span className="bp3-icon"><FTIcon.Heart size="16" /></span>} minimal={true} className="outline white" onClick={()=>this.favorite( 1 )}/>         }
                    
                    
                    </ButtonGroup></div>
                    : ( this.state.next_press ? <><Spinner size={20} /></> : <div className="fox-next-button-group">
                    <ButtonGroup>
                        { !this.props.store.shop_version && <Button minimal={true} icon={<span className="bp3-icon"><FTIcon.Share2 size="16" /></span>} className="outline white" onClick={()=>this.props.history.push("/share/"+this.state.id)}/>  }
                        
                        { this.state.favorite ? <Button icon="heart" minimal={true} className="outline white" onClick={()=>this.favorite( 0 )}/> :  <Button icon={<span className="bp3-icon"><FTIcon.Heart size="16" /></span>} minimal={true} className="outline white" onClick={()=>this.favorite( 1 )}/>         }
                        

                                   
                        <Button minimal={true} icon="chevron-right" className="outline white" onClick={()=>this.onGoNext()}/>
                    
                    </ButtonGroup></div> ))
                    :<>
                    <div className="tips">
                        { tips_string.split("").map( item => {
                            if( item === '_' ) return <span key={span_count++} className="letter">&nbsp;</span>
                        else return <span key={span_count++} className="letter">{item}</span>
                        } ) }
                    </div>
                    { this.state.explain && <div className="explain">
                        {this.state.explain}
                    </div> }
                    </> }
                    
                    
                </div>
                </div>
                {/* <div className="mask" style={mask_style}></div> */}
                <PianoScreen data={this.state.piano} />

            </div>
            { this.props.store.keyboard_open !== 'off' ? <div className="keyboard">
            <FoxKeyBoard answer={this.state.word}/>
            </div> : null }
            
            
        </div>;
    }
}