import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Index from './screen/Index';
import Login from './screen/Login';
import RememberWord from './screen/RememberWord'; 
import DictList from './screen/DictList'; 
import Settings from './screen/Settings'; 
import Buy from './screen/Buy'; 
import Fav from './screen/Fav'; 
import GuideLogin from './screen/GuideLogin'; 
import Share from './screen/Share'; 
import Clean from './screen/Clean'; 





class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/share/:id?" component={Share} />
          <Route path="/guide/login" component={GuideLogin} />
          <Route path="/clean" component={Clean} />
          <Route path="/fav" component={Fav} />
          <Route path="/buy/:type?" component={Buy} />
          <Route path="/settings" component={Settings} />
          <Route path="/dict" component={DictList} />
          <Route path="/login" component={Login} />
          <Route path="/:action?/:args?" component={RememberWord} />
        </Switch>
      </Router>  
    );
  }
}

export default App;