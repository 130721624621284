import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import * as FTIcon from 'react-feather';
import { Button, ButtonGroup, Overlay, Icon } from '@blueprintjs/core';
import { toast } from '../util/Function';
import ModalImage from "react-modal-image";

@withRouter
@inject("store")
@observer
export default class Fav extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    state = {"images":[]};
    
    componentDidMount()
    {
       if( this.props.store.level < 2 )
       {
           toast("收藏功能只对Fox会员开放");
           this.props.history.push("/buy");
       }

       this.load_images();
    }

    async load_images()
    {
        const images = await this.props.store.get_fav_list( this.state.images.length , 20 );
        if( images && images.length > 0 ) this.setState({ "images":this.state.images.concat(images) });
        else
        {
            toast("没有更多收藏了");
        }
        // console.log( images );
    }

    async favorite( id , status = 0 )
    {
        if( !window.confirm("确定要取消收藏吗？") ) return false;
        const newword = await this.props.store.favorite_word( id , status );
        this.setState({"images":this.state.images.filter( item => item.id != newword.id )});
    }

    render()
    {
        const left = <Button minimal={true} icon={<FTIcon.ArrowLeft />} onClick={()=>this.props.history.push("/settings")}  />;

        return <div className="fox-std-page fox-fav-page">
            <div className="header underline">
                <div className="left">
                {left}
                </div>
                <div className="center">
                收藏
                </div>
                <div className="right">
                </div>
            </div>
            <div className="fav-images">
            { this.state.images && this.state.images.map( item => <div key={"image-"+item.id} className="item">
                <div className="float-button-group">

                <ButtonGroup>
                { !this.props.store.shop_version && <Button minimal={true} icon={<span className="bp3-icon"><FTIcon.Share2 size="16" /></span>} className="outline white" onClick={()=>this.props.history.push("/share/"+item.id)}/> }

                { item.favorite ? <Button icon="heart" minimal={true} className="outline white" onClick={()=>this.favorite( item.id ,  0 )}/> :  <Button icon={<span className="bp3-icon"><FTIcon.Heart size="16" /></span>} minimal={true} className="outline white" onClick={()=>this.favorite( item.id, 1 )}/>         }
                </ButtonGroup>

                </div>
                <ModalImage className="coverimage" large={item.image} small={item.image} alt={item.word} />
            </div> )}
            </div>
            <div className="loadbtn">
                <Button large={true} minimal={true} icon="more" text="加载更多" onClick={()=>this.load_images()} className="outline"/>
            </div>
        </div>;
    }
}