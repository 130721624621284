import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';
@withRouter
@inject("store")
@observer
export default class LoadingPage extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    async clean()
    {
        if( window.confirm("确定要清理本地数据么？数据删除后将不可恢复") )
        {
            // 清空 localstorage
            window.localStorage.clear();
            // 清空 indexeddb
            const ret = await this.props.store.db.asyncRemove({}, { multi: true });
            console.log( ret );
            // 重新加载
            window.location.reload();
        }
    }
    
    render()
    {
        return <div className="fox-loading-page" >
            <div className="center" onClick={()=>this.clean()}>
            <Spinner  />
            <div className="desp">如果长时间无响应，可点此清除数据</div>
            </div>
            
        </div>;
    }
}