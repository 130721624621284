import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

@withRouter
@inject("store")
@observer
export default class HelpBox extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        return <div className="helpbox">
            <div>
                <p>欢迎使用🦊福利单词</p>
            </div>
            <div>
                <p>根据释义<br/>
                输入完整单词即可</p>
            </div>

            <div className="light">
                <p>🎈方糖气球 · 
                荣誉出品</p>
            </div>

        </div>;
    }
}