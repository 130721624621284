import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import * as FTIcon from 'react-feather';
import { Button, Switch, Overlay, Icon } from '@blueprintjs/core';
import { toast } from '../util/Function';


@withRouter
@inject("store")
@observer
export default class GuideLogin extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    render()
    {
        const left = <Button minimal={true} icon={<FTIcon.ArrowLeft />} onClick={()=>this.props.history.goBack(1)} />;

        return <div className="fox-std-page fox-guide-page">
            <div className="header underline">
                <div className="left">
                {left}
                </div>
                <div className="center">
                新词库已解锁
                </div>
                <div className="right">
                </div>
            </div>
            <div className="choosebox">
                <div className="image">
                    <img src="/c.png" />
                    <div className="title">
                        新词库已经解锁，领取后可点击左上角查看词库列表。
                    </div>
                </div>
                <div className="actionbar">
               <Button text="登入领取" onClick={()=>this.props.history.push("/login")} large={true} />
                </div>
            </div>
        </div>;
    }
}