import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import * as FTIcon from 'react-feather';
import { Button, HTMLTable, Overlay, Icon } from '@blueprintjs/core';
import { toast , isApiOk , showApiError } from '../util/Function';
import SaleItem from '../component/SaleItem'; 


@withRouter
@inject("store")
@observer
export default class Buy extends Component
{
    state = {"sales_items":[],"show_oto":false};
    
    componentDidMount()
    {
        this.setState({"show_oto":this.props.match.params.type && this.props.match.params.type == 'oto' ? true : false});
        this.load_sales();
    }

    async load_sales()
    {
        
        const { data } = await this.props.store.load_sales();
        if( isApiOk( data ) )
        {
            if( data.data.list )
            {
                this.setState({"sales_items":data.data.list});
                console.log( data.data.list );
            }
        }
        else showApiError( data );
    }

    render()
    {
        const left = <Button minimal={true} icon={<FTIcon.ArrowLeft />} onClick={()=>this.props.history.replace("/settings")}/>;

        // console.log( "this.state.show_oto" , this.state.show_oto );
        if( this.state.sales_items.length > 0 )
        {
            this.props.store.mlog( "buy_page_viewed" );
        }

        const items = this.state.show_oto ? this.state.sales_items.filter( item => item.oto && item.oto == 1 ).sort(  ( a , b ) =>  b.price - a.price ) : this.state.sales_items.filter( item => item.oto != 1 ).sort(  ( a , b ) =>  b.price - a.price );

        const main = <div className="fox-buy-page">
            <div className="header underline">
                <div className="left">
                {left}
                </div>
                <div className="center">
                Fox会员订阅
                </div>
                <div className="right">
                </div>
            </div>
            <ul className="fox-member-price nobottom">
            { items && items.map( item => <SaleItem key={item.id} data={item}/> ) }
            </ul>
            <HTMLTable className="membertable" bordered={true}  striped={true}>
            <thead>
                <tr>
                    <th></th>
                    <th width="25%">游客</th>
                    <th width="25%">免费用户</th>
                    <th width="25%">🦊Fox会员</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>词库</td>
                    <td>基础词库</td>
                    <td>基础词库</td>
                    <td>全部词库</td>
                </tr>
                <tr>
                    <td>背词上限</td>
                    <td>{this.props.store.guest_words_limit}/每日</td>
                    <td>{this.props.store.free_words_limit}/每日</td>
                    <td>无限</td>
                </tr>
                <tr>
                    <td>单词读音</td>
                    <td>-</td>
                    <td>-</td>
                    <td>有</td>
                </tr>
                <tr>
                    <td>跳转设置</td>
                    <td>2秒</td>
                    <td>+可设置3秒</td>
                    <td>+可设置暂停</td>
                </tr>
                <tr>
                    <td>智能复习</td>
                    <td>-</td>
                    <td>-</td>
                    <td>记忆曲线<sup>8</sup></td>
                </tr>
                <tr>
                    <td>进度存储</td>
                    <td>-</td>
                    <td>本地存储</td>
                    <td>+云同步</td>
                </tr>
                <tr>
                    <td>图片收藏</td>
                    <td>-</td>
                    <td>-</td>
                    <td>支持</td>
                </tr>
            </tbody>
            </HTMLTable >
            </div>;
        
        return main;
    }
}